<template>
  <div class="menuBox fatherHeight flex-start">
    <div class="menu-left contBox">
      <el-tree
        :data="menuList"
        ref="treeRef"
        node-key="id"
        :expand-on-click-node="false"
        :default-expanded-keys="defaultExpandIds"
        :props="defaultProps"
        @node-click="handleNodeClick"
      />
    </div>
    <div class="menu-right contBox">
      <div class="explain flex-between">
        <dis class="name">{{ actMenuData.name }}</dis>
        <i class="fa fa-trash-o" @click="delMenu(ruleForm)"></i>
      </div>
      <div class="formBox">
        <el-scrollbar>
          <el-form
            ref="ruleFormRef"
            label-position="top"
            label-width="100px"
            :rules="Rules.ADDMENU"
            :model="ruleForm"
            class="form-item"
          >
            <el-form-item label="菜单名称：" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入" />
            </el-form-item>
            <el-form-item prop="component">
              <template #label>
                <span>组件名称：</span>
                <i class="fa fa-question-circle"></i>
              </template>
              <el-input v-model="ruleForm.component" placeholder="请输入" />
            </el-form-item>
            <el-form-item prop="key">
              <template #label>
                <span>url：</span>
                <i class="fa fa-question-circle"></i>
              </template>
              <el-input v-model="ruleForm.key" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="图标：">
              <el-input v-model="ruleForm.icon" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="排序：">
              <div class="slider">
                <el-slider v-model="ruleForm.sort" :min="1" :max="40" />
              </div>
            </el-form-item>
            <el-form-item label="状态：">
              <el-switch
                v-model="ruleForm.is_use"
                inline-prompt
                :width="60"
                active-text="启"
                inactive-text="禁"
                :active-value="1"
                :inactive-value="0"
              />
            </el-form-item>
            <el-form-item label="可用权限：">
              <div class="tip">
                <el-tag type="danger">
                  权限只能设置在最末的菜单节点，否则权限配置无法显示
                </el-tag>
              </div>
              <div class="cont">
                <template
                  v-if="
                    ruleForm.menuPermission &&
                    ruleForm.menuPermission.length <= 0
                  "
                >
                  <span class="menuPermissionTip">暂无可用权限，请先新增</span>
                  <el-button
                    size="small"
                    @click="addMenuPermission"
                    type="primary"
                  >
                    新增
                  </el-button>
                </template>
                <template v-else>
                  <el-tag
                    v-for="item in ruleForm.menuPermission"
                    :key="item.id"
                  >
                    {{ item.permission.name }}
                  </el-tag>
                  <el-button
                    size="small"
                    @click="addMenuPermission"
                    type="primary"
                  >
                    修改
                  </el-button>
                </template>
              </div>
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>
      <div class="menu-footer">
        <el-dropdown @command="handleCommand">
          <el-button class="color-cancel" type="primary"> 新增菜单 </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="1">当前菜单之后</el-dropdown-item>
              <el-dropdown-item command="2">当前菜单里面</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <el-button
          @click="saveMenu"
          :loading="saveLoad"
          class="color-determine"
          type="primary"
        >
          保存
        </el-button>
      </div>
    </div>
    <BaseDialog
      title="操作"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="600px"
      top="20%"
    >
      <div class="tongluBox mar20">
        <el-form
          ref="addSetupRef"
          :model="addPermission"
          label-width="74px"
          label-position="right"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="赋予权限:">
            <el-select
              v-model="addPermission.permission_ids"
              multiple
              placeholder="请选择"
              style="width: 500px"
            >
              <el-option
                v-for="item in permission"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="accessItem"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'
import Rule from '@/utils/rule'

const menuList = ref([])
const ruleForm = ref({})
ruleForm.value.is_use = 1
const actMenuData = ref({})
const ifAdd = ref(true)
const Rules = ref(Rule)
const visible = ref(false)
const addPermission = ref({})
const defaultExpandIds = ref([])
const defaultProps = {
  children: 'children',
  label: 'name',
  class: (data) => {
    if (data.id == actMenuData.value.id) {
      return 'active'
    } else {
      return ''
    }
  },
}

onMounted(() => {
  getMenuList()
  getPermission()
})
const getMenuList = async () => {
  let res = await service.post('/api/menu/list')
  if (res.code === 0) {
    menuList.value = res.data
    if (res.data.length > 0) {
      actMenuData.value = res.data[0]
      ruleForm.value = JSON.parse(JSON.stringify(res.data[0]))
    }
  }
}
const permission = ref([])
const getPermission = async () => {
  let res = await service.post('api/menu/getPermission')
  if (res.code === 0) {
    permission.value = res.data
  }
}
const handleNodeClick = (data) => {
  if (!ifAdd.value) {
    ElMessageBox.alert('请先保存已新增的菜单', '提示', {
      confirmButtonText: '知道了',
    })
    return
  }
  ruleFormRef.value.clearValidate()
  actMenuData.value = data
  ruleForm.value = JSON.parse(JSON.stringify(data))
}
const handleCommand = (val) => {
  if (!ifAdd.value) {
    ElMessageBox.alert('请先保存已新增的菜单', '提示', {
      confirmButtonText: '知道了',
    })
    return
  }
  if (val == '1') {
    parentAppend()
  } else {
    menuAppend()
  }
}

const treeRef = ref()
let id = 1000
const menuAppend = () => {
  const item = {
    id: id++,
    addMenu: true,
    pid: ruleForm.value.id,
    name: '自定义菜单',
    is_use: 1,
    sort: 1,
    children: [],
  }
  treeRef.value.append(item, actMenuData.value)
  let selected = treeRef.value.getNode(actMenuData.value)
  selected.expanded = true
  actMenuData.value = item
  ruleForm.value = JSON.parse(JSON.stringify(item))
  ifAdd.value = false
}
const parentAppend = () => {
  const item = {
    id: id++,
    addMenu: true,
    pid: ruleForm.value.pid,
    name: '自定义菜单',
    is_use: 1,
    sort: 1,
    children: [],
  }
  treeRef.value.insertAfter(item, actMenuData.value)
  actMenuData.value = item
  ruleForm.value = JSON.parse(JSON.stringify(item))
  ifAdd.value = false
}
const ruleFormRef = ref(null)
const saveLoad = ref(false)
const saveMenu = () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      let item = JSON.parse(JSON.stringify(ruleForm.value))
      let url = ''
      let type = 1
      if (!item.addMenu) {
        url = '/api/menu/edit'
        type = 1
      } else {
        delete item.addMenu
        delete item.id
        url = '/api/menu/create'
        type = 2
      }
      saveLoad.value = true
      let res = await service.post(url, item)
      saveLoad.value = false
      if (res.code === 0) {
        delete ruleForm.value.addMenu
        delete actMenuData.value.addMenu
        ElMessage.success(res.msg)
        ifAdd.value = true
        if (type == 2) {
          ruleForm.value.id = parseInt(res.data.id)
        }
        editMene(ruleForm.value, type)
      }
    }
  })
}
const editMene = (item, type) => {
  actMenuData.value.name = item.name
  actMenuData.value.key = item.key
  actMenuData.value.component = item.component
  actMenuData.value.icon = item.icon
  actMenuData.value.sort = item.sort
  actMenuData.value.is_use = item.is_use
  if (type == 2) {
    actMenuData.value.id = item.id
    let { nodesMap } = treeRef.value.root.store
    let lastExpandIds = []
    let localMap = Object.values(nodesMap)
    localMap.forEach((item) => {
      if (item.expanded) {
        lastExpandIds.push(item.data.id)
      }
    })
    defaultExpandIds.value = lastExpandIds
    menuList.value = [...menuList.value]
  }
}
const delMenu = (item) => {
  ElMessageBox.confirm('确定要删除该菜单吗?', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      if (!ifAdd.value) {
        delItem(item)
        return
      }
      let res = await service.post('/api/menu/delete', {
        id: item.id,
      })
      if (res.code === 0) {
        delItem(item)
      }
    })
    .catch(() => {})
}
const delItem = (item) => {
  ElMessage.success('删除成功')
  ifAdd.value = true
  treeRef.value.remove(item)
  if (item.pid != 0) {
    let selected = treeRef.value.getNode(item.pid)
    actMenuData.value = selected.data
    ruleForm.value = JSON.parse(JSON.stringify(selected.data))
  } else {
    actMenuData.value = menuList.value[0]
    ruleForm.value = JSON.parse(JSON.stringify(menuList.value[0]))
  }
}
const addMenuPermission = () => {
  addPermission.value.menu_id = ruleForm.value.id
  let par = []
  ruleForm.value.menuPermission.map((item) => {
    par.push(item.permission.id)
  })
  addPermission.value.permission_ids = par
  visible.value = true
}
const accessItem = async () => {
  let res = await service.post('/api/menu/add_permission', addPermission.value)
  if (res.code === 0) {
    ElMessage.success(res.msg)
    let menuPermission = []
    res.data.map((item) => {
      let par = {
        permission: {
          ...item,
        },
      }
      menuPermission.push(par)
    })
    ruleForm.value.menuPermission = menuPermission
    actMenuData.value.menuPermission = menuPermission
    visible.value = false
  }
}
</script>

<style scoped lang="scss">
.menu-left {
  width: 216px;
  margin-right: 16px;
  &:deep(.el-tree) {
    .el-tree-node.active > .el-tree-node__content {
      background: rgba(49, 102, 174, 0.3);
      border-radius: 4px;
      color: #409eff;
    }
  }
}
.menu-right {
  flex: 1;
  .formBox {
    height: calc(100% - 80px);
    .form-item {
      padding-right: 16px;
    }
  }
  .menu-footer {
    text-align: right;
    width: 100%;
    margin-top: 0;
    .color-cancel {
      background: #ffffff;
      color: #3166ae;
      margin-right: 10px;
    }
  }
}
.explain {
  height: 32px;
  align-items: center;
  color: #ff3e3e;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0 8px;
  .name {
    font-size: 14px;
    color: #000000;
  }
  .fa {
    cursor: pointer;
  }
}
.slider {
  width: 100%;
  padding: 0 10px;
}
.tip,
.cont {
  width: 100%;
}
.cont {
  margin-top: 8px;
  .el-tag {
    margin-right: 16px;
  }
}
.menuPermissionTip {
  margin-right: 10px;
  color: #999;
}
</style>
